/* CSS for whatworks */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
  background-color: @color-button;
  &:focus,
  &:hover {
    background-color: @color-button--hover;
  }
}

.not-for-ansatte {
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-button;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-button--hover;
      }
    }
  }
  #head-wrapper {
    background: @color-light url("../images/banner.png") no-repeat scroll right
      top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        97% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 190px;
      .head-menu {
        right: 110px;
        top: 15px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-component .vrtx-dropdown-wrapper {
          top: 30px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header img {
        position: absolute;
        top: 55px;
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0) url("../images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

blockquote {
  border-left: 4px solid @color-primary;
  display: block !important;
  font-family: Georgia, serif;
  font-style: italic;
  line-height: 1.563em;
  margin: 20px 65px !important;
  padding: 0 0 0 15px;
}

table th {
  font-weight: bold;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    .col-3-5 {
      .col-1-4 {
        width: 27%;
      }
      .col-1-4:nth-child(4n + 1) {
        width: 19% !important;
      }
      .col-1-4:nth-child(4n + 4) {
        padding-left: 0px;
        .content {
          margin-left: 10px;
        }
      }
      width: 75%;
    }
    .col-2-5 {
      width: 25%;
    }

    background-color: @color-primary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      #head {
        height: 125px;
        #header {
          img {
            padding: 0 10px 10px;
            top: 30%;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-neutral--dark none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-button;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-button--hover;
      }
    }
  }
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box.featured-one-big-two-column
    .vrtx-featured-item
    .vrtx-box-sub-content {
    display: block !important;
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
  .not-for-ansatte {
    #footer-wrapper {
      .col-1-4,
      .col-1-4 .adr > *,
      .col-3-5,
      .col-2-5 {
        width: 100% !important;
      }
      .col-3-5 .col-1-4:nth-child(4n + 1) {
        width: 100% !important;
      }
    }
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #dfdfdf;
@color-secondary: #1dafec;
@color-link: #2771bb;
@color-button: #2974b5;
@color-button--hover: #205a8d; //#5494cc;
// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;
@color-neutral--air: #e5e5e5;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
